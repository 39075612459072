<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 参数设置 </template>
      <template #input>
        <a-input placeholder="请输入名称" v-model="extendValue" />
        <a-input placeholder="请输入参数键名" v-model="dictCode" />
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()">搜索</a-button>
        <a-button
          class="all_boder_btn"
          style="margin-right: 20px"
          @click="openModel()"
          >新建</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => item.id"
        :columns="columns"
        :data-source="tableData"
        :expanded-row-keys.sync="expandedRowKeys"
        @expand="changeexpandedRowKeys"
        @change="onPage"
        :loading="loading"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </template>
        <template slot="dictValue" slot-scope="item">
          <div v-html="item"></div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="blueText">
            <template v-if="item.pcode == 0">
              <span @click="childList(item)">查看子项</span>
              |
              <span @click="openModel(item,true)">新建子项</span>
              |
            </template>
            <span @click="openModel(item)">编辑</span>
            |
            <span style="color: red;" @click="delItem(item.id)">删除</span>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      width="700px"
      :title="(addFlag || (formOBJ.pcode && !formOBJ.id) ? '新建' : '编辑') + '参数'"
      okText="确认"
      :confirm-loading="modelLoading"
      @ok="pushData"
    >
      <a-row class="rows">
        <a-col span="4" class="left">名称</a-col>
        <a-col span="20" class="right">
          <a-input v-model="formOBJ.extendValue" placeholder="请输入名称" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">参数键名</a-col>
        <a-col span="20" class="right">
          <a-input :disabled="!addFlag" v-model="formOBJ.dictCode" placeholder="请输入参数键名" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">参数键值</a-col>
        <a-col span="20" class="right">
          <a-textarea
            v-model="formOBJ.dictValue"
            placeholder="请输入请输入参数键值"
            :auto-size="{ minRows: 3 }"
          />
          <!-- <a-input v-model="formOBJ.dictValue" placeholder="请输入参数键值" /> -->
        </a-col>
      </a-row>
      <a-row class="rows" v-if="formOBJ.pcode != 0">
        <a-col span="4" class="left">排序</a-col>
        <a-col span="20" class="right">
          <a-input-number id="inputNumber" placeholder="请输入排序" v-model="formOBJ.dictSort" :min="1" />
        </a-col>
      </a-row>
      <a-row class="rows" style="margin-bottom: 0">
        <a-col span="4" class="left unrequire">备注</a-col>
        <a-col span="20" class="right">
          <a-textarea
            v-model="formOBJ.content"
            placeholder="请输入备注"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "名称",
    align: "center",
    dataIndex: "extendValue",
  },
  {
    title: "参数键名",
    align: "center",
    dataIndex: "dictCode",
  },
  {
    title: "参数数值",
    align: "center",
    dataIndex: "dictValue",
    scopedSlots: { customRender: "dictValue" },
  },
  {
    title: "备注",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "操作",
    align: "center",
    width: "250px",
    scopedSlots: { customRender: "operation" },
  },
];

import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      modelLoading: false,
      visible: false,
      formOBJ: {},
      addFlag: null,

      dictCode: '',
      extendValue: '',

      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      expandedRowKeys: []
    };
  },
  // 事件处理器
  methods: {
    changeexpandedRowKeys(e,record) {
      let index = this.expandedRowKeys.indexOf(record.id);
      if(index == -1) {
        this.expandedRowKeys.push(record.id);
      } else {
        this.expandedRowKeys.splice(index,1);
      }
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    delItem(id) {
      this.$ajax({
        url: '/hxclass-management/dict/del?id=' + id,
        method: 'DELETE'
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
          this.$message.success('操作成功');
        }
      });
    },
    // 打开新建弹框
    openModel(e,flag) {
      if(e) { // 编辑
        if(flag) { // 新建子项
          let obj = JSON.parse(JSON.stringify(e));
          this.formOBJ = {
            pcode: obj.dictCode
          };
          this.addFlag = true;
        } else { // 正常编辑
          this.formOBJ = JSON.parse(JSON.stringify(e));
          this.addFlag = false;
        }
      }else { // 新建
        this.formOBJ = {};
        this.addFlag = true;
      }
      this.modelLoading = false;
      this.visible = true;
    },
    // 弹框提交
    pushData() {
      if(!this.formOBJ.extendValue) {
        return this.$message.warning('请输入名称')
      } else if(!this.formOBJ.dictCode) {
        return this.$message.warning('请输入参数键名')
      } else if(!this.formOBJ.dictValue) {
        return this.$message.warning('请输入参数键值')
      } else if(this.formOBJ.pcode != 0 && !this.formOBJ.dictSort) {
        return this.$message.warning('请输入排序')
      }
      this.modelLoading = true;
      if(this.addFlag) {
        this.formOBJ['state'] = 1;
      }
      this.$ajax({
        url: '/hxclass-management/dict/update',
        method: 'POST',
        params: this.formOBJ
      }).then((res) => {
        this.modelLoading = false;
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.getList();
          this.$message.success('操作成功');
        }
      });
    },
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/dict/list",
        params: {
          dictCode: this.dictCode,
          extendValue: this.extendValue,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 查看子项
    childList(e) {
      this.$ajax({
        url: '/hxclass-management/dict/get_by_p',
        params: {
          p: e.dictCode
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          if(res.data.length) {
            this.$set(e,'children',res.data);
            this.expandedRowKeys.push(e.id);
            this.expandedRowKeys = Array.from(new Set(this.expandedRowKeys));
            this.$message.success('查询成功！');
          }else{
            this.$message.success('暂无子项数据！');
          }
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: { },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-input-number{
  width: 200px;
}
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 36px 20px;
}
.table-template {
  /deep/.ant-table {
    border: none;
  }
}

.rows {
  margin-bottom: 20px;
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .unrequire{
    &::before {
      content: "";
    }
  }
}
</style>
